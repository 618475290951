.animated-element {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in, transform 3s ease-out;
}

.animated-element.in-view {
  opacity: 5;
  transform: translateY(0%);
}

.animate__animated.animate__bounce {
  --animate-duration: 3s;
}

.custom-bounce {
  animation-duration: 1s; /* Duration of the bounce animation */
  animation-iteration-count: infinite; /* Infinite looping */
  animation-timing-function: ease-in-out; /* Smooth animation */
  animation-delay: 3s; /* Delay between each bounce */
}

.image-slider {
  display: flex;
  gap: 10%;
  width: 100%; /* Adjust to fit the container */
  overflow: hidden;
}

.slide-image {
  min-width: 60%; /* Each image takes up full width of the container */
  animation: slide 10s linear infinite; /* Slide effect */
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  33% {
    transform: translateX(-100%);
  }
  66% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}

.scrolling-text {
  display: flex;
  overflow: hidden;
}

.scroll-content {
  color: #1a4d2e54;
  font-size: 100px;
  font-weight: 800;
  flex-shrink: 0;
  animation: scroll 8s linear infinite;
  padding-left: 100%;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* ===========table view============== */
@media (min-width: 768px) and (max-width:1023px ) {
  .slide-image {
    width: 30%; /* Each image takes up full width of the container */
    animation: slide 12s linear infinite; /* Slide effect */
  }

  .image-slider {
    gap: 20%;
  
  }
}

/* desktop========== */
@media (min-width: 1024px) {
  .animate__animated.animate__bounce {
    --animate-duration: 5s;
  }

  .scroll-content {
    font-size: 200px;
    letter-spacing: 50px;
  }

  .image-slider {
    display: flex;
    /* gap: 10%; */
    width: 100%; /* Adjust to fit the container */
    overflow: hidden;
  }
  
  .slide-image {
    padding-left: 5%;
    min-width: 5%; /* Each image takes up full width of the container */
    animation: slide 10s linear infinite; /* Slide effect */
  }
}

/* ============css for FAQ============= */
.faq-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.faq-item {
  padding: 20px 0;
  transition: all 4s ease;

}

.faq-question {
  background-color: #f3ca52;
  color: #333;
  font-weight: 600;
  cursor: pointer;
  text-align: left;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 18px;
  transition: background-color 1.3s ease;

}

.faq-question:hover {
  background-color: #fdfcf9;
  color: #1a4d2e;
}

.faq-answer {
  overflow: hidden;
  transition: max-height 1.3s ease-out, padding 0.3s ease-out;
  text-align: justify;
}
