
.sidebar {
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
  animation: slideIn 1.8s forwards;
}

/* Slide in animation */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.heroBar{
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
  animation: heroIn 2s forwards;
}

@keyframes heroIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 2.1s ease-in-out;
}

.logo {
  color: white;
  z-index: 60;
  width: 80px;
  height: 75px;
}

.top-one {
  background-color: #F6E9B2;
  padding: 8px;
  border-radius: 50%;
  z-index: 90;
  color: #1a4d2e;
}

.burger-icon {
  font-size: 26px;
}

.hero-text {
  font-weight: 900;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 70px;
 
}



/* =================Tablet View================== */

@media (min-width: 768px) and (max-width: 1023px) {

  .hero-button {
    gap: 20px;
    height: 60px;
    width: 50%;
    font-size: 20px;
    border-radius: 15px;
  }

 
}

/* =============Desktop View======================= */

@media (min-width: 1024px) and (max-width: 1366px) {


}
